import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import Bookmark from "./Bookmark";
import Bookmarks from "./Bookmarks";
import Header from "./Header";
import Login from "./Login";
import Tags from "./Tags";
import allActions from "./actions";
import "./resources/css/bulma.css";
import history from "./utils/history";

library.add(faSignOutAlt);

export default function App() {
	const [bookmarksChanged, setBookmarksChanged] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [selectedTagId, setSelectedTagId] = React.useState();
	const [editBookmarkId, setEditBookmarkId] = React.useState();
	const bookmarkModalVisible = useSelector(
		(state) => state.page.bookmarkModalVisible
	);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (!loading) setBookmarksChanged(false);
	}, [loading]);

	React.useEffect(() => {
		if (editBookmarkId) {
			dispatch(allActions.pageActions.setBookmarkModalVisible(true));
		}
	}, [dispatch, editBookmarkId]);

	return (
		<Router history={history}>
			<div className="app">
				<Header />
				<section className="section">
					<nav className="container level tag-list">
						<div className="level-left">
							<Tags selectedTagId={selectedTagId} />
						</div>
					</nav>
					<div className="container">
						<Switch>
							<Route exact path="/">
								<Bookmarks
									bookmarksChanged={bookmarksChanged}
									setLoading={setLoading}
									setEditBookmarkId={setEditBookmarkId}
									setSelectedTagId={setSelectedTagId}
								/>
							</Route>
							<Route path="/login">
								<Login />
							</Route>
							<Route
								path="/tags/:tagId"
								children={
									<Bookmarks
										bookmarksChanged={bookmarksChanged}
										setLoading={setLoading}
										setEditBookmarkId={setEditBookmarkId}
										setSelectedTagId={setSelectedTagId}
									/>
								}
							/>
						</Switch>
					</div>
				</section>
			</div>
			<Bookmark
				visible={bookmarkModalVisible}
				setBookmarksChanged={setBookmarksChanged}
				editBookmarkId={editBookmarkId}
				setEditBookmarkId={setEditBookmarkId}
			/>
		</Router>
	);
}
