import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import allActions from "./actions";
import { cleanupBookmarks, logout } from "./utils/common";
import history from "./utils/history";

export default function Header() {
	const dispatch = useDispatch();
	const location = useLocation();
	const [cleaningUp, setCleaningUp] = React.useState(false);

	function doLogout() {
		logout().then((successFul) => {
			if (successFul) {
				dispatch(allActions.userActions.setIsLoggedIn(false));
				history.push("/login");
			} else {
				dispatch(allActions.userActions.setIsLoggedIn(false));
				console.log("Error logging out");
			}
		});
	}

	function cleanup() {
		setCleaningUp(true);
		cleanupBookmarks().then(() => {
			setCleaningUp(false);
		});
	}

	return (
		<>
			{location?.pathname !== "/login" && (
				<nav className="navbar" role="navigation" aria-label="main navigation">
					<div className="navbar-brand">
						<a className="navbar-item" href="/">
							Bookmarkr
						</a>
					</div>

					<div id="navbar" className="navbar-menu is-active">
						<div className="navbar-end">
							<div className="navbar-item">
								<div className="buttons">
									<button
										className={`button ${cleaningUp && "is-loading"}`}
										onClick={() => cleanup()}
									>
										Cleanup
									</button>
									<button
										className="button"
										onClick={() =>
											dispatch(
												allActions.pageActions.setBookmarkModalVisible(true)
											)
										}
									>
										Add bookmark
									</button>
									<button className="button" onClick={() => doLogout()}>
										<FontAwesomeIcon icon="sign-out-alt" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</nav>
			)}
		</>
	);
}
