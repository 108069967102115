const setIsLoggedIn = (loggedIn) => {
    return {
        type: "SET_IS_LOGGED_IN",
        payload: loggedIn,
    }
}

const setUsername = (user) => {
    return {
        type: "SET_USERNAME",
        payload: user,
    }
}

export default {
    setIsLoggedIn,
    setUsername,
}