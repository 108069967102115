import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import allActions from "./actions";
import {
	loadBookmark,
	loadTags,
	saveBookmark,
	saveBookmarkBatch,
} from "./utils/common";

library.add(faTrashAlt);

export default function Bookmark(props) {
	const { visible, setBookmarksChanged, setEditBookmarkId, editBookmarkId } =
		props;

	const [url, setUrl] = React.useState("");
	const [urls, setUrls] = React.useState("");
	const [tags, setTags] = React.useState([]);
	const [selectedTags, setSelectedTags] = React.useState([]);
	const inputRef = React.useRef(null);
	const textareaRef = React.useRef(null);
	const [isDeleted, setIsDeleted] = React.useState(false);
	const [batchMode, setBatchMode] = React.useState(false);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (visible) {
			loadTags().then((data) => {
				setTags(data);
			});
		}
	}, [visible]);

	React.useEffect(() => {
		resetElements();

		if (editBookmarkId) {
			loadBookmark(editBookmarkId).then((data) => {
				console.log(data);
				setUrl(data.url);
				setIsDeleted(data.deleted);
				const tagIds = data.tags.map((tag) => tag.id);
				setSelectedTags(tagIds);
				inputRef.current.focus();
			});
		}
	}, [editBookmarkId, visible]);

	function toggleTag(tagId) {
		const tagIndex = selectedTags.indexOf(tagId);
		if (tagIndex > -1) {
			let tags = [...selectedTags];
			tags.splice(tagIndex, 1);
			setSelectedTags(tags);
		} else {
			setSelectedTags([...selectedTags, tagId]);
		}
	}

	function resetAndUpdate() {
		setBookmarksChanged(true);
		resetElements();
		setEditBookmarkId();
	}

	function resetElements() {
		setUrls("");
		setUrl("");
		setSelectedTags([]);
	}

	function saveBookmarkClicked(event) {
		event.preventDefault();

		if (url) {
			dispatch(allActions.pageActions.setBookmarkModalVisible(false));
			if (batchMode) {
				saveBookmarkBatch(urls, selectedTags).then(() => resetAndUpdate());
			} else {
				saveBookmark(editBookmarkId, url, selectedTags).then(() =>
					resetAndUpdate()
				);
			}
		}
	}

	function clearInput() {
		setUrl("");
		inputRef.current.focus();
	}

	function closeModal() {
		setUrl("");
		dispatch(allActions.pageActions.setBookmarkModalVisible(false));
		setEditBookmarkId();
	}

	return (
		<div className={`modal ${visible ? "is-active" : ""}`}>
			<div className="modal-background"></div>
			<form onSubmit={saveBookmarkClicked}>
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">{`${
							editBookmarkId ? "Edit" : "Add"
						} bookmark`}</p>
						<button
							className="delete"
							aria-label="close"
							onClick={closeModal}
						></button>
					</header>
					<section className="modal-card-body">
						<label className="checkbox is-pulled-right">
							<input
								type="checkbox"
								checked={batchMode}
								onChange={() => setBatchMode(!batchMode)}
							/>{" "}
							Batch mode
						</label>

						<div className="field">
							<label className="label">{batchMode ? "URLs" : "URL"}</label>
						</div>

						{!batchMode && (
							<div className="field has-addons">
								<div className="control is-expanded">
									<input
										className="input"
										type="text"
										placeholder="URL"
										onChange={(event) => setUrl(event.target.value)}
										value={url}
										ref={inputRef}
									/>
								</div>
								<div className="control">
									<div
										className="button is-delete"
										onClick={() => clearInput()}
									>
										<FontAwesomeIcon icon="trash-alt" />
									</div>
								</div>
							</div>
						)}

						{batchMode && (
							<div className="field has-addons">
								<div className="control is-expanded">
									<div className="control is-expanded">
										<textarea
											class="textarea"
											placeholder="List of URLs, each on a separate line"
											rows="10"
											onChange={(event) => setUrls(event.target.value)}
											value={urls}
											ref={textareaRef}
										/>
									</div>
								</div>
							</div>
						)}

						<div className="field">
							<label className="label">Tags</label>
							<div className="control">
								<div className="tags">
									{tags &&
										tags
											.sort((a, b) => (a.name > b.name ? 1 : -1))
											.map((tag) => (
												<span
													key={tag.id}
													className={`tag ${
														selectedTags.includes(tag.id) ? "is-link" : ""
													}`}
													onClick={() => toggleTag(tag.id)}
												>
													{tag.name}
												</span>
											))}
								</div>
							</div>
						</div>

						<div className="field">
							<label className="checkbox">
								<input
									type="checkbox"
									checked={isDeleted}
									onChange={() => setIsDeleted(!isDeleted)}
								/>{" "}
								Deleted
							</label>
						</div>
					</section>
					<footer className="modal-card-foot">
						<input type="submit" className="button is-success" value="Save" />
						<button className="button" onClick={closeModal}>
							Cancel
						</button>
					</footer>
				</div>
			</form>
		</div>
	);
}
