import React from 'react'
import { saveTag } from './utils/common'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faTrashAlt);

export default function Tag(props) {
    const {
        visible,
        setVisible,
        setTagsChanged,
        addMode=true,
    } = props

    const [name, setName] = React.useState('')
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        if (visible) {
            inputRef.current.focus();
        }
    }, [visible])

    function saveTagClicked() {
        setVisible(false)
        saveTag(null, name).then(() => {
            setName("")
            setTagsChanged(true)
        })
    }

    function closeModal() {
        setName("")
        setVisible(false)
    }

    return (
        <div className={`modal ${visible ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{`${ addMode ? "Add" : "Edit"} tag`}</p>
                    <button className="delete" aria-label="close" onClick={closeModal}></button>
                </header>
                <section className="modal-card-body">
                    <div className="field has-addons">
                        <div className="control is-expanded">
                            <input 
                                className="input" 
                                type="text" 
                                placeholder="Name" 
                                onChange={event => setName(event.target.value)}
                                value={name}
                                ref={inputRef}
                            />
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-success" onClick={saveTagClicked}>Save</button>
                    <button className="button" onClick={closeModal}>Cancel</button>
                </footer>
            </div>
        </div>
    );
}