import Cookies from "js-cookie";
import allActions from "../actions";
import { store } from "./store";

const host =
	window.location.hostname === "localhost"
		? "http://localhost:5001/api/"
		: window.location.protocol + "//bookmarks.martijnkranen.com/api/";
const loginUrl = host + "users/login";
const logoutUrl = host + "users/logout";
const tagsUrl = host + "tags";
const saveTagUrl = host + "tags/save";
const bookmarkUrl = host + "bookmarks/";
const bookmarksUrl = host + "bookmarks";
const bookmarksForTagUrl = host + "bookmarks/tag/";
const saveBookmarkUrl = host + "bookmarks/save";
const saveBookmarkBatchUrl = host + "bookmarks/save/batch";
const deleteBookmarkUrl = host + "bookmarks/delete/";
const cleanupBookmarksUrl = host + "bookmarks/cleanup";

const authTokenKey = "auth-token";
let authToken;

export function getAuthToken() {
	authToken = Cookies.get(authTokenKey);
	return authToken;
}

export function setAuthToken(token) {
	if (token) {
		Cookies.set(authTokenKey, token);
	} else {
		// Cookies.remove(authTokenKey)
	}
}

export async function login(username, password) {
	return new Promise(function (resolve, reject) {
		return fetch(loginUrl, {
			method: "POST",
			body: JSON.stringify({
				username: username,
				password: password,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					const token = data.data.token;
					setAuthToken(token);
					store.dispatch(allActions.userActions.setIsLoggedIn(true));
				} else {
					store.dispatch(allActions.userActions.setIsLoggedIn(false));
				}
				resolve(data);
			});
	});
}

export async function logout() {
	return new Promise(function (resolve, reject) {
		return fetch(logoutUrl, {
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => response.json())
			.then((data) => resolve(data));
	});
}

export async function cleanupBookmarks() {
	return new Promise(function (resolve, reject) {
		return fetch(cleanupBookmarksUrl, {
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => response.json())
			.then((data) => resolve(data));
	});
}

export async function loadTags() {
	return new Promise(function (resolve, reject) {
		return fetch(tagsUrl, {
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (!response.ok) {
					logout();
					return;
				}
				return response.json();
			})
			.then((data) => {
				resolve(data);
			});
	});
}

export async function saveTag(id = null, name) {
	return new Promise(function (resolve, reject) {
		return fetch(saveTagUrl, {
			method: "POST",
			body: JSON.stringify({
				id: id,
				name: name,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (!response.ok) {
					logout();
					resolve();
				}
				return response.json();
			})
			.then((data) => resolve(data));
	});
}

export async function loadBookmarks(tagId = null) {
	return new Promise(function (resolve, reject) {
		let url = tagId ? bookmarksForTagUrl + tagId : bookmarksUrl;
		return fetch(url, {
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (!response.ok) {
					logout();
					return;
				}
				return response.json();
			})
			.then((data) => resolve(data))
			.catch(function (error) {
				reject(error.status);
			});
	});
}

export async function loadBookmark(id) {
	return new Promise(function (resolve, reject) {
		return fetch(bookmarkUrl + id, {
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (!response.ok) {
					logout();
					return;
				}
				return response.json();
			})
			.then((data) => resolve(data));
	});
}

export async function saveBookmark(id, url, tags) {
	return new Promise(function (resolve, reject) {
		return fetch(saveBookmarkUrl, {
			method: "POST",
			body: JSON.stringify({
				id: id,
				url: url,
				tags: tags,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (!response.ok) {
					logout();
					resolve();
				}
				return response.json();
			})
			.then((data) => resolve(data));
	});
}

export async function saveBookmarkBatch(urls, tags) {
	return new Promise(function (resolve, reject) {
		return fetch(saveBookmarkBatchUrl, {
			method: "POST",
			body: JSON.stringify({
				urls: urls,
				tags: tags,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (!response.ok) {
					logout();
					resolve();
				}
				return response.json();
			})
			.then((data) => resolve(data));
	});
}

export async function deleteBookmark(id) {
	return new Promise(function (resolve, reject) {
		return fetch(deleteBookmarkUrl + id, {
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (!response.ok) {
					logout();
					return;
				}
				return response.json();
			})
			.then((data) => resolve(data));
	});
}
