import React from 'react'
import { useDispatch } from 'react-redux';
import { login } from './utils/common'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock, faCheck } from '@fortawesome/free-solid-svg-icons'
import history from "./utils/history"
import allActions from './actions'

library.add(faUser);
library.add(faLock);
library.add(faCheck);

export default function Login() {
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [errorMessage, setErrorMessage] = React.useState()
    const usernameRef = React.useRef(null)
    const dispatch = useDispatch()

    function doLogin(event) {
        event.preventDefault()
        
        setErrorMessage()
        login(username, password).then(result => {
            if (result.success) {
                dispatch(allActions.userActions.setUsername(result.data.username))
                dispatch(allActions.userActions.setIsLoggedIn(true))
                history.push("/")                
            } else {
                dispatch(allActions.userActions.setUsername())
                dispatch(allActions.userActions.setIsLoggedIn(false))
                setErrorMessage("Username or password not correct")
            }
        })
    }

    return (
        <form onSubmit={doLogin}>
            { errorMessage && (
                <article class="message is-danger">
                    <div class="message-body">
                        {errorMessage}
                    </div>
                </article>
            )}
            <div className="field">
                <p className="control has-icons-left has-icons-right">
                    <input 
                        className="input" 
                        type="text" 
                        placeholder="Username"
                        onChange={event => setUsername(event.target.value)}
                        value={username}
                        ref={usernameRef}
                    />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon="user" />
                    </span>
                    <span className="icon is-small is-right">
                        <FontAwesomeIcon icon="check" />
                    </span>
                </p>
            </div>
            <div className="field">
                <p className="control has-icons-left">
                    <input 
                        className="input" 
                        type="password" 
                        placeholder="Password"
                        onChange={event => setPassword(event.target.value)}
                        value={password}
                    />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon="lock" />
                    </span>
                </p>
            </div>
            <div className="field">
                <p className="control">
                    <input type="submit" className="button is-success" value="Login" />
                </p>
            </div>
        </form>
    )
}