import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Tag from "./Tag";
import { loadTags } from "./utils/common";

library.add(faPlus);

function Tags(props) {
	const { selectedTagId = null } = props;
	const [tags, setTags] = React.useState([]);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [tagsChanged, setTagsChanged] = React.useState(false);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

	function refreshTags() {
		loadTags().then((data) => {
			setTags(data);
		});
	}

	React.useEffect(() => {
		refreshTags();
	}, []);

	React.useEffect(() => {
		refreshTags();
	}, [isLoggedIn]);

	React.useEffect(() => {
		if (tagsChanged) {
			refreshTags();
		}
	}, [tagsChanged]);

	return (
		<>
			{tags && tags.length > 0 && (
				<>
					<div className="field is-grouped is-grouped-multiline">
						<div className="control">
							<div className="tags">
								<Link
									to="/"
									className={`tag is-link ${selectedTagId ? "is-light" : ""}`}
								>
									All
								</Link>
								<Link
									to="/tags/deleted"
									className={`tag is-link ${
										selectedTagId === "deleted" || !selectedTagId
											? ""
											: "is-light"
									}`}
								>
									Deleted
								</Link>
								{tags &&
									tags
										.sort((a, b) => (a.name > b.name ? 1 : -1))
										.map((tag) => (
											<Link
												to={`/tags/${tag.id}`}
												key={tag.id}
												className={`tag is-link ${
													selectedTagId && tag.id !== parseInt(selectedTagId)
														? "is-light"
														: ""
												}`}
											>
												{tag.name}
											</Link>
										))}
								<div
									className="tags has-addons"
									onClick={() => setModalVisible(true)}
								>
									<span to="/" className="tag is-light">
										Add
									</span>
									<span className="tag">
										<span className="icon is-small">
											<FontAwesomeIcon icon="plus" />
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<Tag
						visible={modalVisible}
						setVisible={setModalVisible}
						setTagsChanged={setTagsChanged}
					/>
				</>
			)}
		</>
	);
}

export default Tags;
