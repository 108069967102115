const initialState = {
	isLoggedIn: null,
	username: null,
};

const user = (state = initialState, action) => {
	switch (action.type) {
		case "SET_IS_LOGGED_IN":
			return {
				...state,
				isLoggedIn: action.payload,
			};
		case "SET_USERNAME":
			return {
				...state,
				username: action.payload,
			};
		default:
			return state;
	}
};

export default user;
