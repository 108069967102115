import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { deleteBookmark, loadBookmarks } from "./utils/common";
import history from "./utils/history";

library.add(faTrashAlt);
library.add(faEdit);

export default function Bookmarks(props) {
	const { bookmarksChanged, setLoading, setEditBookmarkId, setSelectedTagId } =
		props;

	const [bookmarks, setBookmarks] = React.useState([]);
	const [selectedId, setSelectedId] = React.useState();
	const [confirmDeleteVisible, setConfirmDeleteVisible] = React.useState(false);
	const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const { tagId } = useParams();

	const refreshBookmarks = React.useCallback(() => {
		setLoading(true);

		loadBookmarks(tagId)
			.then((data) => {
				setBookmarks(data.sort((a, b) => (a.id > b.id ? 1 : -1)));
				setLoading(false);
			})
			.catch(function (error) {
				if (error === 401) {
					history.push("/login");
				}
				console.log(error);
			});
	}, [tagId, setLoading]);

	React.useEffect(() => {
		refreshBookmarks();
	}, [refreshBookmarks]);

	React.useEffect(() => {
		setSelectedTagId(tagId);
		refreshBookmarks();
	}, [refreshBookmarks, setSelectedTagId, tagId]);

	React.useEffect(() => {
		if (bookmarksChanged) {
			refreshBookmarks();
		}
	}, [bookmarksChanged, refreshBookmarks]);

	// React.useEffect(() => {
	// 	if (isLoggedIn === false) {
	// 		history.push("/login");
	// 	}
	// }, [isLoggedIn]);

	function editBookmark(id) {
		setLoading(true);
		setEditBookmarkId(id);
	}

	function confirmDelete(id) {
		setSelectedId(id);
		setConfirmDeleteVisible(true);
	}

	function deleteSelectedBookmark() {
		setConfirmDeleteVisible(false);
		deleteBookmark(selectedId).then(() => refreshBookmarks());
	}

	return (
		<div>
			<ul className="bookmarks">
				{bookmarks &&
					bookmarks.map((bookmark) => (
						<li key={bookmark.id} className="bookmark">
							<a href={bookmark.url} target="_blank" rel="noopener noreferrer">
								{bookmark.title ? bookmark.title : bookmark.url}
							</a>
							<span className="tags">
								{bookmark.tags &&
									bookmark.tags.map((tag) => (
										<Link
											to={`/tags/${tag.id}`}
											key={tag.id}
											className="tag is-link"
										>
											{tag.name}
										</Link>
									))}
								<div className="tag" onClick={() => editBookmark(bookmark.id)}>
									<FontAwesomeIcon icon="edit" />
								</div>
								<div className="tag" onClick={() => confirmDelete(bookmark.id)}>
									<FontAwesomeIcon icon="trash-alt" />
								</div>
							</span>
						</li>
					))}
			</ul>
			<div className={`modal ${confirmDeleteVisible ? "is-active" : ""}`}>
				<div className="modal-background"></div>
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">Delete bookmark</p>
						<button
							className="delete"
							aria-label="close"
							onClick={() => setConfirmDeleteVisible(false)}
						></button>
					</header>
					<section className="modal-card-body">
						<h1>Are you sure?</h1>
					</section>
					<footer className="modal-card-foot">
						<button
							className="button is-success"
							onClick={deleteSelectedBookmark}
						>
							Yes
						</button>
						<button
							className="button"
							onClick={() => setConfirmDeleteVisible(false)}
						>
							No
						</button>
					</footer>
				</div>
			</div>
		</div>
	);
}
