const initialState = {
    bookmarkModalVisible: false,
}

const page = (state = initialState, action) => {
    switch(action.type) {
        case "SET_BOOKMARKS_MODAL_VISIBLE":
            return {
                ...state,
                bookmarkModalVisible: action.payload,
            }
        default:
            return state
    }
}

export default page;